import React from "react"
import Layout from "../../components/Layout"
import Head from "../../components/Head"
import "../../styles/categories.css"
import adicionesData from "../../utils/menu/adicionesData"
import { Link } from "gatsby"

const adiciones = () => (
  <>
    <Head
      description="Tenemos adiciones para tu pedido como papa frita, papa salada, arroz y ensalada"
      title="Menu: Adiciones - Asadero RoqueFer"
      keywords="arroz papa ensalada papa frita"
    />
    <Layout>
      <main>
        <div className="products-container">
          <h3>Adiciones</h3>
          {adicionesData.map(current => {
            return (
              <div className="product">
                <Link to="/pedidos">
                  <picture>
                    <source
                      srcSet={current.imageDesktopSrc}
                      media="(min-width: 500px)"
                    />
                    <img
                      loading="lazy"
                      src={current.imageSrc}
                      alt={current.imageDescription}
                    />
                  </picture>
                </Link>
              </div>
            )
          })}
        </div>
      </main>
    </Layout>
  </>
)

export default adiciones
