import arroz from "../../../static/assets/images/menu/adiciones/porcion_arroz.jpg"
import arroz_desktop from "../../../static/assets/images/menu/adiciones/desktop/ARROZ.jpg"
import ensalada from "../../../static/assets/images/menu/adiciones/ensalada.jpg"
import ensalada_dektop from "../../../static/assets/images/menu/adiciones/desktop/ENSALADA.jpg"
import papaFrita from "../../../static/assets/images/menu/adiciones/papas_fritas.jpg"
import papaFrita_desktop from "../../../static/assets/images/menu/adiciones/desktop/PAPA_FRITAS.jpg"

const adicionesData = [
  {
    imageSrc: arroz,
    imageDesktopSrc: arroz_desktop,
    imageDescription: "Adición de arroz",
  },
  {
    imageSrc: ensalada,
    imageDesktopSrc: ensalada_dektop,
    imageDescription: "Adición de ensalada",
  },
  {
    imageSrc: papaFrita,
    imageDesktopSrc: papaFrita_desktop,
    imageDescription: "Adición de papa frita",
  },
]

export default adicionesData
